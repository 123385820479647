<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="automatic-line-item-group"
        navigation="automatic-line-item-groups"
        :onNew="newItem"
        title="Automatic Line Groups">
        <template slot-scope="{ item, data }">
            <BT-Field-String
                :isEditing="data.isEditing || data.isNew"
                label="Group Name"
                v-model="item.groupName" />

            <BT-Sidebar-List
                v-if="!data.isNew"
                buttonClass="ma-1 primary"
                inline
                label="Customers"
                manyNavigation="customers"
                filterProp="AutomaticLineItemGroupID"
                :singleID="item.id"
                :itemProperties="['ID','Buyer','AutomaticLineItemGroupID']"
                singleProp="automaticLineItemGroupID"
                small
                manyText="buyer.companyName"
                :searchProps="['buyer.companyName']" />

            <BT-Sidebar-List
                v-if="!data.isNew"
                buttonClass="ma-1 primary"
                inline
                linkGetURL="/get/Links?AutomaticLineItemGroupID={id}"
                label="Automatic Lines"
                linkNavigation="automatic-line-item-groups"
                manyText="lineItemName"
                manyProp="automaticLineItemID"
                manyToMany
                manyNavigation="automatic-line-items"
                :searchProps="['lineItemName']"
                :singleID="item.id"
                singleProp="automaticLineItemGroupID"
                small />

                
            <!-- <v-data-table 
                dense
                hide-default-footer
                :headers="headers"
                :items="item.lineItems"
                item-key="id">

                <template v-slot:top>
                    <v-toolbar flat dense>
                        <v-toolbar-title>Line Items</v-toolbar-title>
                        <v-divider class="ml-4" inset vertical />
                        <v-btn 
                            v-if="data.isEditing || data.isNew" 
                            small 
                            icon 
                            @click="newLineItem"><v-icon small>mdi-plus</v-icon></v-btn>

                        <v-dialog v-model="showEdit" max-width="500px">
                            <v-card>
                                <v-card-title class="headline">Line Item</v-card-title>
                                <v-card-text>
                                    <v-form v-if="currentLine">
                                        <v-container>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="currentLine.lineItemName" :rules="requiredRules()" required label="Item Name" />
                                                    <v-text-field v-model.number="currentLine.calcOrder" label="Calculating Order" />
                                                    <v-select
                                                        :items="ruleOptions"
                                                        item-text="text"
                                                        item-value="value"
                                                        v-model="currentLine.rule"
                                                        label="Rule"/> 

                                                    <v-checkbox v-model="currentLine.isLastMinute" label="Calculate Last Minute" />

                                                    <v-text-field v-if="isQuantityBased(currentLine)" type="number" v-model.number="currentLine.quantity" label="Quantity" />
                                                    <v-text-field v-else-if="isValueBased(currentLine)" type="number" prefix="$" v-model.number="currentLine.quantity" label="Value" />
                                                    <v-text-field v-else-if="isTagBased(currentLine)" v-model.number="currentLine.quantity" label="Tag Value"/>
                                                    <BT-Select 
                                                        v-else-if="isProductBased(currentLine)"
                                                        v-model="currentLine.itemID"
                                                        itemText="productName"
                                                        itemValue="id"
                                                        label="Product"
                                                        navigation="products" />

                                                    <v-checkbox v-if="isProductBased(currentLine)" v-model="currentLine.isReplacement" label="Replace Produce Line Item" />

                                                    <BT-Tax-Type v-model="currentLine.taxType" />

                                                    <BT-Tags
                                                        v-if="!isProductBased(currentLine)"
                                                        v-model="currentLine.linesWithTags" label="Only Notice Lines With Certain Tags"/>

                                                    <v-text-field v-model="currentLine.description" label="Line Description" />

                                                    <v-text-field v-model.number="currentLine.sortNumber" label="Sort Number"/>

                                                    <v-select
                                                        v-if="!isProductBased(currentLine)"
                                                        :items="['Currency', 'Percent']"
                                                        v-model="currentLine.unitType"
                                                        label="Unit Value Type" />

                                                    <v-text-field
                                                        v-model.number="currentLine.unitValue"
                                                        label="Unit Price / Value"
                                                        :suffix="currentLine.unitType == 'Percent' ? '% (0.10 is 10%)' : null"
                                                        :prefix="currentLine.unitType == 'Currency' ? '$' : null" />

                                                    <BT-Tags v-model="currentLine.tags" label="Tags" :options="['GST']"/>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="finishEdit(item)">OK</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>  

                <template v-slot:[`item.actions`]="d">
                    <BT-Dialog
                        v-if="data.isEditing"
                        icon="mdi-source-branch-sync"
                        title="Xero Mappings"
                        label="Xero Mappings"
                        width="450"
                        okText=""
                        cancelText=""
                        small
                        :persistent="false">
                        <template>
                            <BT-Mappings
                                navigation="order-invoice-mappings"
                                itemType="Product"
                                externalParty="xero"
                                :itemID="d.item.id" />
                        </template>
                    </BT-Dialog>
                    <v-btn
                        v-if="data.isEditing"
                        @click="editItem(d.item, item)"
                        small
                        icon>
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="data.isEditing"
                        @click="deleteItem(d.item, item)"
                        small
                        icon>
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </template>

            </v-data-table> -->
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Automatic-Line-Item-Group-Blade',
    components: {
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        // BTDialog: () => import('~components/BT-Dialog.vue'),
        // BTMappings: () => import('~components/BT-Mappings.vue')
    },
    data: function() {
        return {
            showEdit: false,
            currentLine: null,
            isNewLine: false,
            panelV: 0,
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        headers() { 
            return [
            { text: 'Order', value: 'calcOrder' },
            { text: 'Name', value: 'lineItemName' },
            { text: 'Description', value: 'description' },
            { text: 'Rule', value: 'rule' },
            { text: 'Actions', value: 'actions', align: 'right' }
        ]},
        ruleOptions() {
            return [
                { text: 'Product Value Greater Than', value: 'ProductValueGreaterThan' },
                { text: 'Product Value Less Than', value: 'ProductValueLessThan' },
                { text: 'Product Value Equal To', value: 'ProductValueEqualTo' },
                { text: 'Total Value Greater Than', value: 'TotalValueGreaterThan' },
                { text: 'Total Value Less Than', value: 'TotalValueLessThan' },
                { text: 'Total Value Equal To', value: 'TotalValueEqualTo' },
                { text: 'Total Quantity Greater Than', value: 'TotalQuantityGreaterThan' },
                { text: 'Total Quantity Less Than', value: 'TotalQuantityLessThan' },
                { text: 'Total Quantity Equal To', value: 'TotalQuantityEqualTo' },
                { text: 'Product Exists', value: 'ProductExists' }
            ];
        }
    },
    methods: {
        newItem() {
            return {
                groupName: null,
                lineItems: []
            }
        },
        newLineItem() {
            this.isNewLine = true;
            this.currentLine = { };
            this.showEdit = true;
        },
        editItem(item) {
            this.isNewLine = false;
            this.currentLine = item; //this.copyDeep(item);
            this.showEdit = true;
        },
        deleteItem(item, group) {
            if (item.id) {
                group.lineItems.splice(group.lineItems.findIndex(x => x.id == item.id), 1);
            }
            else {
                group.lineItems.splice(group.lineItems.findIndex(x => x === item));
            }
        },
        closeEdit() {
            this.currentLine = null;
            this.isNewLine = false;
            this.showEdit = false;
        },
        finishEdit(group) {
            if (this.isNewLine) {
                group.lineItems.push(this.currentLine);
            }
            this.showEdit = false;            
        },
        isQuantityBased(lineItem) {
            return lineItem.rule == 'TotalQuantityGreaterThan' || lineItem.rule == 'TotalQuantityLessThan' || lineItem.rule == 'TotalQuantityEqualTo';
        },
        isTagBased(lineItem) {
            return lineItem.rule == 'LinesWithTag';
        },
        isProductBased(lineItem) {
            return lineItem.rule == 'ProductExists';
        },
        isValueBased(lineItem) {
            return lineItem.rule == 'ProductValueGreaterThan' || lineItem.rule == 'ProductValueLessThan' || lineItem.rule == 'ProductValueEqualTo' ||
                lineItem.rule == 'TotalValueGreaterThan' || lineItem.rule == 'TotalValueLessThan' || lineItem.rule == 'TotalValueEqualTo';
        },
    }
}
</script>